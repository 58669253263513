import React, { useState } from 'react';

import FacebookIcon from '../../../images/Facebook';
import PinterestIcon from '../../../images/Pinterest';
import ShareIcon from '../../../images/Share';
import TwitterIcon from '../../../images/Twitter';

import style from './PostSocialLinks.scss';

import cn from 'classnames';
import copyToClipboard from 'copy-to-clipboard';

interface ISocialLinksProps {
  className?: string;
  feature_image?: string;
}

// TODO: use class component to set currentURL in componentDidMount
export const PostSocialLinks = (props: ISocialLinksProps) => {
  const currentURL = typeof window !== 'undefined' ? window.location.href : '';

  const [contentState, setContentState] = useState('');

  function handleCopyClick() {
    const url = window.location.href;
    const isCopied = copyToClipboard(url);

    setContentState(isCopied ? url : '');

    setTimeout(() => {
      setContentState('');
    }, 2000);
  }

  return (
    <div className={cn(props.className, style.socialLinks)}>
      <p>Share:</p>
      {props.feature_image ? (
        <a
          href={`https://pinterest.com/pin/create/button/?url=${currentURL}&media=${props.feature_image}&image_url=${props.feature_image}`}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Share on Pinterest"
        >
          <PinterestIcon />
        </a>
      ) : null}
      <a
        href={'https://twitter.com/intent/tweet?url=' + currentURL}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Share on Twitter"
      >
        <TwitterIcon />
      </a>
      <a
        href={'https://www.facebook.com/sharer/sharer.php?u=' + currentURL}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Share on Facebook"
      >
        <FacebookIcon />
      </a>
      <span className={style.copyToClipboard} onClick={handleCopyClick}>
        <ShareIcon />
        {contentState !== '' ? <span>Copied!</span> : null}
      </span>
    </div>
  );
};
