import React from 'react';

import SuccessMark from '../../images/SuccessMark';
import SignUpForm, { SUBSCRIBED_COOKIE_NAME } from '../Form/SignUp';

import style from './SignUp.scss';

import cn from 'classnames';

interface ISignUpBannerProps {
  className?: string;
  btnText?: string;
}

const SignUpBannerMini = ({ className, btnText }: ISignUpBannerProps) => {
  if (typeof document !== 'undefined' && document.cookie.includes(SUBSCRIBED_COOKIE_NAME)) {
    return null;
  }

  return (
    <div className={cn(style.mini, style.signUpBanner, className)}>
      <div className="container">
        <div className="row align-items-end">
          <div className="col-12">
            <h2>Want to earn an extra $100 this week?</h2>
            <p>Get weekly tips on ways to earn extra cash from TSR.</p>
          </div>
          <div className="col-12">
            <SignUpForm className={style.form} btnText={btnText}>
              {/* Render children on subscribed */}
              <div className={style.successMessage}>
                <SuccessMark />
                <p>
                  Success! Now check your email <br />
                  to confirm your subscription.
                </p>
              </div>
            </SignUpForm>
            <p>
              By clicking on 'subscribe,' you agree to our{' '}
              <a href="/terms-and-conditions/">Terms</a> and <a href="/privacy-policy/">Policy</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpBannerMini;
