import React, { CSSProperties } from 'react';

import SignUpBannerMini from '../components/Banners/SignUpMini';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { FinancialTools } from '../components/FinancialTools/FinancialTools';
import { PostAuthor } from '../components/Post/Author';
import { PostAuthorDescription } from '../components/Post/Author/Description';
import { IPostCard } from '../components/Post/Card';
import { PostSocialLinks } from '../components/Post/SocialLinks';
import ProgressBarDesktop from '../components/ProgressBar/ProgressBarDesktop';
import RenderOnVisible from '../components/RenderOnVisible';
import { TableOfContents } from '../components/TableOfContents/TableOfContents';
import {
  GhostFeaturedImageLinkWrap,
  renderGhostAuthorAside,
  renderGhostChatBubbles,
  renderGhostImageFloat,
  renderGhostInlineTOC,
} from '../utils/ghostSnippets';
import { GhostAuthorArticlesElements } from '../utils/GhostSnippets/AuthorArticlesElements';
import { GhostSuggestedElements } from '../utils/GhostSnippets/SuggestedElements';
import { optimizeContent } from '../utils/helpers';
import { lazyLoading } from '../utils/lazyLoading';
import trustAllScripts from '../utils/trustAllScripts';

import stylePost from '../styles/pages/post.scss';
import style from '../styles/pages/post.scss';

import { Tags } from '@tryghost/helpers-gatsby';
import cn from 'classnames';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Sticky, StickyContainer } from 'react-sticky';

interface IPostProps {
  data: {
    ghostPost: IPostCard;
    allGhostPost: { edges: { node: IPostCard }[] };
    allGhostAuthor: {
      edges: {
        node: {
          name: string;
          slug: string;
          profile_image: string;
          bio: string;
          postCount: number;
        };
      }[];
    };
    allAuthorsBioFullJson: { edges: { node: { slug: string; bio: string } }[] };
  };
  location: any;
}

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

// RenderOnVisible since content not visible on most screens
// Extracted since onRouteUpdate from gatsby-browser.js runs before mounted
const Content = ({
  content,
  allGhostAuthor,
}: {
  content: string;
  allGhostAuthor: {
    edges: {
      node: {
        name: string;
        slug: string;
        profile_image: string;
        bio: string;
      };
    }[];
  };
}) => {
  React.useEffect(() => {
    trustAllScripts();

    renderGhostImageFloat();

    renderGhostInlineTOC();

    renderGhostChatBubbles(allGhostAuthor.edges);

    renderGhostAuthorAside(allGhostAuthor.edges);

    lazyLoading();
  }, []);

  if (!content) {
    return null;
  }

  return (
    <section
      className={cn(
        'load-external-scripts',
        stylePost.singleArticleFullContent,
        stylePost.singleArticleBodyContent
      )}
      dangerouslySetInnerHTML={{
        __html: optimizeContent(content),
      }}
    />
  );
};

const Post = React.memo(({ data, location }: IPostProps) => {
  const { allGhostAuthor, allAuthorsBioFullJson, ghostPost } = data;

  const {
    html = '',
    tags,
    primary_author,
    codeinjection_styles,
    title,
    custom_excerpt,
    feature_image,
    published_at,
  } = ghostPost;

  React.useEffect(() => {
    tags.push(
      tags.splice(
        tags.findIndex((tag) => tag && tag.slug === 'guest-posts'),
        1
      )[0]
    );

    // Note, some authors have no full bio.
    const authorsBioFull = allAuthorsBioFullJson.edges.find(({ node }) => {
      return node.slug === primary_author.slug;
    });

    if (authorsBioFull) {
      primary_author.bio = authorsBioFull.node.bio;
    }
  }, []);

  const ghostDisclosure =
    typeof window !== 'undefined' &&
    document &&
    document.getElementById('ghostAdditionalDisclosure')?.innerHTML;

  const ghostLinkWrap =
    typeof window !== 'undefined' &&
    document &&
    document.querySelectorAll('.ghostFeaturedImageLinkWrap')?.[0];

  return (
    <>
      <MetaData data={data} location={location} />
      <Helmet>
        <style type="text/css">{`${codeinjection_styles}`}</style>
      </Helmet>
      <Layout postTitle={title} mainClass={style.main} isArticle>
        <StickyContainer className={stylePost.sticky}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-3 d-none d-lg-block">
                <Sticky>
                  {({ style, isSticky }: { style: CSSProperties; isSticky: boolean }) => (
                    <div style={{ ...style }}>
                      <RenderOnVisible>
                        <ProgressBarDesktop
                          className={isSticky ? stylePost.stickyPrograssBar : ''}
                          title={title}
                          content={html}
                        />
                      </RenderOnVisible>
                    </div>
                  )}
                </Sticky>
              </div>
              <article className={cn('col-12 col-lg-6', stylePost.singleArticle)}>
                <section className={stylePost.singleArticleFullContent}>
                  <div className="row">
                    <div className="col">
                      {tags && (
                        <Tags
                          classes={cn(stylePost.singleArticleTags, style.featuredTag)}
                          post={ghostPost}
                          visibility="public"
                          limit={1}
                          permalink="/tag/:slug/"
                        />
                      )}
                    </div>
                    <div className="col-auto">
                      <p className={style.disclaimerText}>
                        <Link to="/disclosure/">Advertiser Disclosure</Link>{' '}
                        {`${ghostDisclosure || ''}`}
                      </p>
                    </div>
                  </div>
                  <h1 className={stylePost.singleArticleTitle}>{title}</h1>
                  {custom_excerpt ? (
                    <p className={stylePost.singleArticleExcerpt}>{custom_excerpt}</p>
                  ) : null}
                  <div className="d-md-flex justify-content-between">
                    <PostAuthor
                      publishedAt={published_at}
                      primary_author={primary_author}
                      post={ghostPost}
                    />
                  </div>
                </section>
                {feature_image ? (
                  <figure className={stylePost.singleArticleFeatureImage}>
                    <GhostFeaturedImageLinkWrap>
                      <img className="lazy" height="220px" data-src={feature_image} alt={title} />
                    </GhostFeaturedImageLinkWrap>
                    {!ghostLinkWrap && (
                      <PostSocialLinks
                        className={cn('d-md-none', style.singleArticleAuthorLinks)}
                        feature_image={feature_image}
                      />
                    )}
                  </figure>
                ) : null}
                <TableOfContents className="d-lg-none" title={title} content={html} />
                {/* The main post content */}
                <Content content={html} allGhostAuthor={allGhostAuthor} />
                <PostSocialLinks feature_image={feature_image} />
              </article>
              <section className={cn('d-none d-lg-block col-lg-3')}>
                {/* <Sticky>
                  {({ style: stickyStyle }: { style: CSSProperties; isSticky: boolean }) => (
                    <div style={{ ...stickyStyle }}> */}
                <div className={style.sidebar}>
                  <SignUpBannerMini />
                  <FinancialTools />
                  <div id="ghostMiscSideBarContent" />
                </div>
                {/* </div>
                  )}
                </Sticky> */}
              </section>
            </div>
          </div>
        </StickyContainer>
        <section className={stylePost.singleArticleInformation}>
          {tags && tags.length > 0 && (
            <div className={stylePost.singleArticleFooterTags}>
              <div className="container">
                <h6>Discover what matters to you</h6>
                <Tags
                  classes={stylePost.singleArticleTags}
                  post={ghostPost}
                  visibility="public"
                  separator=""
                  permalink="/tag/:slug/"
                />
              </div>
            </div>
          )}
          <div className="container">
            <PostAuthorDescription
              primary_author={primary_author}
              postCount={
                allGhostAuthor.edges.find(({ node }) => node.slug === primary_author.slug)?.node
                  .postCount || 0
              }
            />
            <GhostAuthorArticlesElements ghostPost={ghostPost} />
            <GhostSuggestedElements ghostPost={ghostPost} />
          </div>
        </section>
        <style
          dangerouslySetInnerHTML={{
            __html: `
/*Mediavine Mobile Fix*/
@media only screen and (max-width: 425px) {
  article.col-12.col-lg-8.m-auto {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ > .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ {
    padding-left: 37px !important;
    padding-right: 37px !important;
  }
}
@media only screen and (max-width: 359px) {
  article.col-12.col-lg-8.post--singleArticle--4N84x {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  article.col-12.col-lg-8.m-auto {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ > .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
/*Back to Top CSS*/
body.mediavine-videohas-sticky #pe-widget-bell {
  bottom: 300px !important;
}
body.adhesion:not(.mediavine-videohas-sticky) #pe-widget-bell {
  bottom: 120px !important;
}
`,
          }}
        ></style>
      </Layout>
    </>
  );
});

export default Post;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
    allGhostAuthor {
      edges {
        node {
          name
          slug
          bio
          profile_image
          postCount
        }
      }
    }
    allAuthorsBioFullJson {
      edges {
        node {
          bio
          slug
        }
      }
    }
  }
`;
