import React from 'react';

import Arrow from '../../images/ArrowMini';

import style from './TableOfContents.scss';

import cn from 'classnames';

interface IProps {
  content: string;
  title: string;
  titleId?: string;
  className?: string;
}

interface IState {
  sideNav: [];
  currentActiveHeading: string;
}

const defaultTitleId: string = 'main-heading';

export class TableOfContents extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentActiveHeading: '',
      sideNav: [],
    };
  }

  componentDidMount() {
    this.updateSideNav(this.props.title);
  }

  updateSideNav(currentActiveHeading: string) {
    const { content } = this.props;
    const sideNav: any = [];
    const doc = new DOMParser().parseFromString(content, 'text/html');
    const headings = doc.querySelectorAll('h1, h2, h3');

    if (headings.length <= 1) {
      return;
    }

    const addSideNavItem = (id: string, tagName: 'H1' | 'H2' | 'H3', text: string) => {
      sideNav.push(
        <li
          key={id}
          className={cn(style[tagName], {
            [style.active]: currentActiveHeading === id,
          })}
          data-id={id}
          onClick={this.handleSideNavLiClick}
        >
          <Arrow rotate={-45} />
          <span data-id={id} onClick={this.handleSideNavLiClick}>
            {text}
          </span>
        </li>
      );
    };

    if (headings.length > 0) {
      sideNav.push(
        <div key="tocTitle" className={style.title}>
          Jump Ahead
        </div>
      );
    }

    headings.forEach((val: any) => {
      const headingRendered = document.createElement('div');
      headingRendered.innerHTML = val.innerHTML;

      addSideNavItem(
        val.id,
        val.tagName,
        headingRendered.textContent ? headingRendered.textContent.trim() : ''
      );
    });

    this.setState({ sideNav });
  }

  handleSideNavLiClick = (e: any): void => {
    const { titleId = defaultTitleId } = this.props;
    const id = e.target.dataset.id;
    const el = document.getElementById(id);

    this.updateSideNav(id);

    if (id === titleId) {
      // if main page title, scroll to top of page
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    } else if (el) {
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = el.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top - 96;

      window.scrollTo({
        behavior: 'smooth',
        top: offset,
      });
    }
  };

  render() {
    return (
      <>
        <div
          id="tableOfContentsWrapper"
          className={cn(this.props.className, style.tableOfContentsWrapper)}
        >
          <ul className={style.toc}>{this.state.sideNav}</ul>
        </div>
      </>
    );
  }
}
