import React from 'react';

interface IIconProps {
  className?: string;
  width?: number;
  height?: number;
}

const BookIcon = (props: IIconProps) => {
  const { className, width = 36, height = 36 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M33 28.5C26.3333 28.5 23 29.4 18 33C13 29.4 9.66667 28.5 3 28.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M18 27C23 23.7857 26.3333 23.7857 33 23.7857V3C26.3333 3 23 3 18 6.21428M18 27C13 23.7857 9.66667 23.7857 3 23.7857V3C9.66667 3 13 3 18 6.21428M18 27V6.21428"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default React.memo(BookIcon);
