import React from 'react';

import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';
import { PostCard } from '../../../components/Post/Card';

import stylePost from '../../../styles/pages/post.scss';

import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';

const AuthorArticlesElements = (props: any) => {
  const { data } = props;
  const { allGhostPost } = data;

  // get all posts for the author
  const authorPosts = allGhostPost.edges.filter((o: any) =>
    Object.entries(o).some(([k, value]: any) => {
      return value.primary_author.slug === props.ghostPost.primary_author.slug;
    })
  );

  const handleReadMoreDebt = () => {
    window.location.href = `/author/${props.ghostPost.primary_author.slug}/`;
  };

  return (
    <div>
      <div
        className={cn(stylePost.authorArticles, {
          [stylePost.authorArticlesDouble]: authorPosts.length === 2,
        })}
      >
        {authorPosts.slice(0, 3).map(({ node }: any) => (
          <PostCard
            className={cn({
              [stylePost.authorArticleSingle]: authorPosts.length === 1,
            })}
            key={node.id}
            post={node}
          />
        ))}
      </div>
      <SecondaryButton className={stylePost.readMore} onClick={handleReadMoreDebt}>
        Read more from {props.ghostPost.primary_author.name}
      </SecondaryButton>
    </div>
  );
};

export const GhostAuthorArticlesElements = (props: {
  ghostPost: {
    title: string;
    slug: string;
    tags: {
      slug: string;
      name: string;
    }[];
  };
}) => {
  const renderDefaultLayoutSettings = (data: any) => (
    <AuthorArticlesElements data={data} {...props} />
  );
  return (
    <StaticQuery
      query={graphql`
        query GhostAuthorArticlesQuery {
          allGhostPost(sort: { order: DESC, fields: published_at }, limit: 100) {
            edges {
              node {
                id
                slug
                title
                published_at
                feature_image
                primary_author {
                  name
                  slug
                  profile_image
                }
                html
                excerpt
                tags {
                  slug
                  name
                }
              }
            }
          }
        }
      `}
      render={renderDefaultLayoutSettings}
    />
  );
};
