import React from 'react';

import { Avatar } from '../../../../components/Avatar/Avatar';
import Posts from '../../../../images/Posts';

import style from './Description.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

interface IProps {
  primary_author: {
    name: string;
    slug: string;
    profile_image: string;
    bio: string;
  };
  postCount: number;
}

export const PostAuthorDescription = ({ primary_author, postCount }: IProps) => {
  const { profile_image, slug, name, bio } = primary_author;
  return (
    <div className={cn(style.wrapper, style.authorWrapper)}>
      <div className="container">
        <Avatar profile_image={profile_image} name={name} />
        <h2 className={style.title}>
          <Link to={`/author/${slug}/`}>{name.split('@')[0]}</Link>
        </h2>
        <div className={style.count}>
          <Posts />
          {postCount} {postCount === 1 ? 'post' : 'posts'}
        </div>
        {bio && <p dangerouslySetInnerHTML={{ __html: bio }} />}
      </div>
    </div>
  );
};
