import React from 'react';

import Book from '../../images/Book';
import Calculator from '../../images/Calculator';
import Financial from '../../images/Financial';
import HowMuch from '../../images/HowMuch';

import style from './FinancialTools.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export interface IFinancialToolsProps {
  className?: string;
}

export const FinancialTools = (props: IFinancialToolsProps) => {
  return (
    <div className={cn(style.financialTools, props.className)}>
      <h3>
        <Financial /> Financial Tools
      </h3>
      <Link to="/retirement-calculator/" className={style.base}>
        <h4>Retirement Calculator: How Much Do I Really Need to Retire?</h4>
        <div>
          <Calculator width={36} />
        </div>
      </Link>
      <Link to="/how-to-invest/" className={style.base}>
        <h4>How to invest when you feel like you're behind</h4>
        <div>
          <HowMuch />
        </div>
      </Link>
      <Link to="/millennial-retirement-plan/" className={style.base}>
        <h4>The Millennial Retirement Plan So Crazy It Just Might Work</h4>
        <div>
          <Book />
        </div>
      </Link>
    </div>
  );
};
