import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const FinancialIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M21.5 11H21C19.3431 11 18 12.3431 18 14V14C18 15.6569 19.3431 17 21 17H21.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2 4V15.6C2 17.8402 2 18.9603 2.43597 19.816C2.81947 20.5686 3.43139 21.1805 4.18404 21.564C5.03968 22 6.15979 22 8.4 22H15.6C17.8402 22 18.9603 22 19.816 21.564C20.5686 21.1805 21.1805 20.5686 21.564 19.816C22 18.9603 22 17.8402 22 15.6V12.4C22 10.1598 22 9.03968 21.564 8.18404C21.1805 7.43139 20.5686 6.81947 19.816 6.43597C18.9603 6 17.8402 6 15.6 6H12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2 4C2 2.89543 2.89543 2 4 2H10.8C11.9733 2 12.56 2 13.0985 2.15378C13.5754 2.28999 14.0228 2.5137 14.4179 2.8135C14.864 3.15201 15.216 3.62134 15.92 4.56L17 6H4C2.89543 6 2 5.10457 2 4V4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(FinancialIcon);
