import React from 'react';

import { Avatar } from '../../Avatar/Avatar';
import { IPostCard } from '../Card';
import PostStats from '../Stats';

import style from './PostAuthor.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

interface IProps {
  publishedAt: string;
  primary_author: {
    name: string;
    slug: string;
    profile_image: string;
  };
  post: IPostCard;
}

export const PostAuthor = ({ publishedAt, primary_author, post }: IProps) => (
  <div className={cn(style.author)}>
    <Avatar
      className={style.profileImage}
      profile_image={primary_author.profile_image}
      name={primary_author.name}
    />
    <div className={style.authorText}>
      <Link to={`/author/${primary_author.slug}/`}>
        {primary_author ? primary_author.name : ''}
      </Link>
      <PostStats publishedAt={publishedAt} isReadingTimeShown post={post} />
    </div>
  </div>
);
