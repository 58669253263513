import React from 'react';

interface IIconProps {
  className?: string;
  width?: number;
  height?: number;
}

const HowMuchIcon = (props: IIconProps) => {
  const { className, width = 36, height = 36 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.304 22.8259L29.8722 19.9692C30.8889 19.4366 32.0994 19.7755 32.6804 20.7439C33.3099 21.7607 32.9709 23.068 32.0026 23.6974L20.9632 30.5244C19.559 31.396 17.7675 31.1539 16.6055 29.9918L12.8289 26.1668C11.4247 24.7626 9.53641 23.9395 7.55126 23.9395H6.05029V16.822H8.22912C10.8921 16.822 13.5067 17.2578 16.0245 18.0325L23.142 20.3566C24.0619 20.7439 24.643 21.8091 24.304 22.8259ZM24.304 22.8259C24.0135 23.7459 23.0452 24.3269 22.0768 24.1332L14.5719 22.487"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        opacity="0.5"
        d="M2 25V16"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.5"
        d="M23.2875 17.1609C26.5766 17.1609 29.243 14.4946 29.243 11.2055C29.243 7.91635 26.5766 5.25 23.2875 5.25C19.9984 5.25 17.332 7.91635 17.332 11.2055C17.332 14.4946 19.9984 17.1609 23.2875 17.1609Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default React.memo(HowMuchIcon);
